import React, { useEffect, useRef } from 'react';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import clsx from 'clsx';
import useTimer from 'hooks/useTimer';
import { saveStatisticsTimerForQuestion } from 'requests/QuestionsRequests';
import AlertIcon from 'components/icons/AlertIcon';

const styles = theme => ({
    wrapper: {
        width: 80,
        position: 'absolute',
        left: 0,
        top: 0,
        zIndex: 10,
        height: '100%',
        display: 'flex',
        padding: '30px 5px',
        flexDirection: 'column',
        backgroundColor: theme.palette.grey[200],
        borderTopLeftRadius: 4,
        borderBottomLeftRadius: 4
    },
    item: {
        background: theme.palette.grey[300],
        fontSize: 14,
        marginBottom: 4,
        padding: '8px 0',
        fontWeight: 700,
        minWidth: 60,
        minHeight: 65,
        height: 65,
        borderRadius: 4,
        color: theme.palette.grey[800],
        '&:hover': {
            backgroundColor: theme.palette.grey[400],
            color: theme.palette.grey[900]
        }
    },
    itemAlert: {
        color: theme.palette.yellow[900],
        '& svg': {
            marginRight: 2
        }
    },
    itemActive: {
        color: theme.palette.primary.main,
        backgroundColor: '#fff'
    }
});


export default withStyles(styles)(({
    classes, questionNumber, questions,
    gotoQuestion, propPublic, setScreenSelected,
    hasScreenQuestions, screenSelected
}) => {
    const { time: questionSpentTime, startTimer, resetTimer } = useTimer(1, 1000);
    const prevQuestionNumber = useRef(null);

    const handleSendTime = async () => {
        if (prevQuestionNumber.current !== null && !propPublic && prevQuestionNumber.current !== 0) {
            await saveStatisticsTimerForQuestion(questions[prevQuestionNumber.current].id, { value: questionSpentTime });
        }
    };

    const handleClick = async (key) => {
        await handleSendTime();
        prevQuestionNumber.current = key;
        gotoQuestion(key);
        resetTimer();
    };

    useEffect(() => {
        startTimer();
        return () => {
            handleSendTime();
        };
    }, []);

    return (
        <div className={classes.wrapper}>
            {
                hasScreenQuestions && (
                    <Button
                        onClick={() => setScreenSelected(true)}
                        className={clsx(classes.item, screenSelected && classes.itemActive)}
                    >
                        Screen
                    </Button>
                )
            }
            {
                questions.map(({ manualGradeMissing }, key) => (
                    <Button
                        key={key}
                        onClick={() => handleClick(key)}
                        className={clsx(classes.item, manualGradeMissing && classes.itemAlert, (questionNumber === parseInt(key, 10) && !screenSelected) && classes.itemActive)}
                    >
                        {manualGradeMissing && <AlertIcon size={16} />}
                        Q{key + 1}
                    </Button>
                ))
            }
        </div>
    );
});
