import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import DOMPurify from 'dompurify';
import TopPerformerLabel from 'components/candidate_cards/TopPerformerLabel';
import PhoneNumber from 'awesome-phonenumber';
import { GOOGLE_DOC, PRESENTATION, SPREADSHEET, SPREADSHEET_AUTO_GRADED } from 'helper/constants';
import HiredLabel from 'pages/assessment/results_assessment/common/HiredLabel';
import colors from '../components/colors';
import { appStore } from '../components/appStore';
import { reset } from './eventSegment';

export function downloadCSV(csv, slug) {
    const blob = new Blob([csv], {
        type: 'text/csv'
    });
    const link = document.createElement('a');

    link.download = `${slug}-${new Date().toISOString().slice(0, 10)}.csv`;
    link.href = window.URL.createObjectURL(blob);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export function checkPluralNew(number, singularForm, pluralForm = `${singularForm}s`) {
    return parseInt(number, 10) === 1 ? singularForm : pluralForm;
}

export function objectLength(object) {
    if (object) {
        return Object.keys(object).length;
    }
    return 0;
}

export async function asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
    }
}

export function generateLanguageArray(codeChalange, activeLanguage) {
    const langs = [
        { name: 'NodeJs', mode: 'text/javascript', active: false },
        { name: 'PHP', mode: 'text/x-php', active: false },
        { name: 'C++', mode: 'text/x-c++src', active: false },
        { name: 'Python3', mode: 'text/x-python', active: false },
        { name: 'Ruby', mode: 'text/x-ruby', active: false },
        { name: 'Go', mode: 'text/x-go', active: false },
        { name: 'C', mode: 'text/x-csrc', active: false },
        { name: 'Java', mode: 'text/x-java', active: false },
        { name: 'C#', mode: 'text/x-csharp', active: false }
    ];

    if (codeChalange) {
        const languages = codeChalange.reduce((langsArr, { language }) => [...langsArr, language], []);
        return langs.filter((lang) => {
            if (languages.some(item => lang.name.toLowerCase() === item)) {
                return lang;
            }
            return null;
        });
    }
    return langs.reduce((str, { name, mode }) => {
        if (name.toLowerCase() === activeLanguage) {
            str = mode;
        }
        return str;
    }, '');
}

export function secondsToTimeObject(value) {
    let seconds = parseInt(value, 10);
    const days = Math.floor(seconds / (3600 * 24));
    seconds -= days * 3600 * 24;
    const hours = Math.floor(seconds / 3600);
    seconds -= hours * 3600;
    const minutes = Math.floor(seconds / 60);
    seconds -= minutes * 60;

    return {
        days,
        hours,
        minutes,
        seconds
    };
}

export function secondsToTime(timeInSeconds) {
    const addZeroIfNeeded = (value1) => {
        if (value1 < 10) return `0${value1}`;
        return value1;
    };
    const { days, hours, minutes, seconds } = secondsToTimeObject(timeInSeconds);

    if (days) {
        return `${days} ${hours > 1 ? 'DAYS' : 'DAY'}`;
    }
    if (hours) {
        return `${hours} ${hours > 1 ? 'HOURS' : 'HOUR'}`;
    }

    if (hours && minutes) {
        return `${hours} ${hours > 1 ? 'HOURS' : 'HOUR'} ${minutes} MINUTES`;
    }

    return `${addZeroIfNeeded(minutes)}:${addZeroIfNeeded(seconds)}`;
}

export function getInitials(str = '') {
    return str.split(' ').reduce((string, item) => {
        if (item && string.length < 2) {
            string += item[0].toUpperCase();
        }
        return string;
    }, '');
}

export function formatDateItem(dateItem) {
    return dateItem < 10 ? `0${dateItem}` : dateItem;
}

export function timeObjectToFormattedString(timeObj) {
    const { days, hours, minutes, seconds } = timeObj;
    if (days >= 2) {
        return `${days} days`;
    }
    if (hours) {
        return `${formatDateItem((days * 24) + hours)}:${formatDateItem(minutes)}hrs`;
    }
    if (minutes) {
        return `${formatDateItem(minutes)}:${formatDateItem(seconds)}min`;
    }
    return `${seconds}sec`;
}

export function removeTokensFromLocalStorage() {
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('token');
    sessionStorage.removeItem('refreshToken');
    sessionStorage.removeItem('token');
    localStorage.removeItem('library-filter');
    localStorage.removeItem('library-filter-params');
    reset();
}

export function clone(obj) {
    return JSON.parse(JSON.stringify(obj));
}

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function capitalizeEachFirstLetter(string) {
    if (!string) return '';
    return string.toLowerCase().split(' ').map(s => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');
}

export function round(value, precision) {
    const multiplier = 10 ** (precision || 0);
    return Math.round(value * multiplier) / multiplier;
}

export function checkHasCompanyFeature(company, featureName) {
    if (!company) return false;
    const { features } = company;
    return features && (featureName in features);
}

export function checkCompanyCanBeUpgraded(company) {
    if (!company || !company.plan) return false;
    return company.plan.canBeUpgraded;
}

export function isNumeric(n) {
    return !Number.isNaN(parseFloat(n)) && Number.isFinite(n);
}

export function getScoreColor(score) {
    if (score === null || score === false || !isNumeric(+score)) return '';
    if (score >= 80 && score <= 100) {
        return colors.green[600];
    } if (score >= 40 && score < 80) {
        return colors.yellow[800];
    }
    return colors.red[600];
}

export function getScoreColorByParam(score) {
    switch (score) {
        case 'LOW': return colors.red[600];
        case 'MEDIUM': return colors.yellow[800];
        case 'HIGH': return colors.green[600];
        default: return colors.grey[800];
    }
}

export function getAppHeader() {
    const appHeader = document.getElementById('app_header');
    if (appHeader && appHeader.offsetHeight) return appHeader;
}

export function checkHasUserPermission(company, permission, featureName) {
    const hasCompanyPermissions = checkHasCompanyFeature(company, 'PERMISSIONS');
    if (!hasCompanyPermissions || !permission) return true;
    return permission[featureName];
}

export function nth(number) {
    if (number > 3 && number < 21) return `${number}th`;
    switch (number % 10) {
        case 1: return `${number}st`;
        case 2: return `${number}nd`;
        case 3: return `${number}rd`;
        default: return `${number}th`;
    }
}

export function returnCandidateLabel(hired, topPerformer) {
    if (hired) return <HiredLabel />;
    if (topPerformer) return <TopPerformerLabel />;
    return null;
}

export function cloneChildrenWithNewProps(children, propsObj) {
    if (!children) return null;
    return Array.isArray(children)
        ? React.Children.map(children, child => React.cloneElement(child, propsObj))
        : React.cloneElement(children, propsObj);
}

export function highlightTextByCoordinates(text, start, end) {
    const arr = text.split('');
    arr[end] = `${arr[end]}</mark>`;
    arr[start] = `<mark>${arr[start]}`;
    return ReactHtmlParser(DOMPurify.sanitize(arr.join('')));
}

export async function copyTextToClipboard(text, callback, attemptCount = 0) { // doesn't work not on the localhost
    if (!navigator.clipboard) {
        return Promise.reject(new Error('fail')).then(() => {},
            () => {
                appStore.flashMessage('Copying is not supported in your browser');
            });
    }

    try {
        await navigator.clipboard.writeText(text);
        if (callback) callback();
    } catch (err) {
        if (!attemptCount) {
            copyTextToClipboard(text, callback, 1);
        } else {
            console.log(`Failed to copy: ${err}`);
            appStore.flashMessage(`Failed to copy: ${err}`);
        }
    }
}

export function isPhoneValid(value) {
    if (!value) return true;
    const phone = value[0] === '+' ? value.trim() : `+${value.trim()}`;
    return PhoneNumber(`${phone}`).isValid();
}

export function isGoogleQuestion(newType) {
    return (
        [SPREADSHEET, SPREADSHEET_AUTO_GRADED, PRESENTATION, GOOGLE_DOC].some(item => item === newType)
    );
}

export function makePluralString(number, singleForm, pluralForm = `${singleForm}s`) {
    return number === 1 ? singleForm : pluralForm;
}

export function getBaseUrl() {
    const getUrl = window.location;
    const { protocol, host } = getUrl;
    return `${protocol}//${host}`;
}

export function removePropertyFromObject(obj, value) {
    const { [value]: current, ...newObj } = obj;
    return newObj;
}

export function validateEmail(email) {
    const re = /\S+@\S+\.\S+/;
    return re.test(String(email).toLowerCase());
}


export function nl2brCustom(string) {
    if (!string) return '';
    return string.replace(/(\r\n|\r|\n)/g, '<br>');
}

function decodeHtmlEntities(str) {
    if (!str) return '';
    return str
        .replace(/&nbsp;/g, ' ')
        .replace(/&lt;/g, '<')
        .replace(/&gt;/g, '>')
        .replace(/&amp;/g, '&')
        .replace(/&quot;/g, '"')
        .replace(/&#39;/g, "'");
}

export function convertHtmlToStringWithSpaces(markupStr) {
    return markupStr?.replace(/<(?:.|\n)*?>/gm, ' ');
}

export function getOnboardingLocation(pathname) {
    if (pathname.includes('candidate') && pathname.includes('select')) {
        return 'candidate report card';
    }
    if (pathname.includes('select')) {
        return 'select';
    }
    if (pathname.includes('start-assessment')) {
        return 'assesment';
    }
    if (pathname.includes('assessments')) {
        return 'library';
    }
}

export function downloadFile(data, filename, mime) {
    const blob = new Blob([data], { type: mime || 'application/octet-stream' });
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.style.display = 'none';
    link.href = url;
    link.download = filename;

    document.body.appendChild(link);
    link.click();

    window.URL.revokeObjectURL(url);
    document.body.removeChild(link);
}

export function formatTimestamp(timestamp) {
    const date = new Date(timestamp);
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-US', options);
    return formattedDate;
}
