export default theme => ({
    text: {
        fontSize: 14,
        lineHeight: '24px',
        color: '#000',

        '& a': {
            fontWeight: 700,
            color: '#000'
        },

        '& mark': {
            backgroundColor: '#fff1be'
        }
    },
    answersWrapper: {
        display: 'flex',
        flexWrap: 'no-wrap',
        justifyContent: 'space-between',
        '& > div': {
            width: '48%'
        },
        '& p': {
            marginBottom: 5
        }
    },
    candidateName: {
        fontSize: 16
    },
    anonymous: {
        fontSize: 16,
        color: theme.palette.grey[500]
    },
    questionTitle: {
        fontSize: 20,
        fontWeight: 700
    }
});
